import React from 'react'
import './irvingCrea.css'

export default function IrvingCrea() {
    return (
        <div className='irvingContainer'>
            <h1 className='irvingTitle'>
                IRVING CREA PAGE COMING SOON
            </h1>
        </div>
    )
}